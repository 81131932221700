.fig-inline {
    float: right;
    width: 40%;
    height: 250px;
    margin: 5px 5px;    
    img {
        position: relative;
        width: 100%;
        height: 85%;
        object-fit: cover;
    }
    div{
        position: relative;
        
        p {
            position: absolute;
            top: -25px;
            right: 5px;
            text-align: right;
            font-size: small;
            color: #709bb5;
        }
    
        figcaption {
            padding: 10px 10px 5px 10px;
            position: relative;
            line-height: 1em;
            font-style: italic;
            text-align: center;
        }
    }
}

.fig-block {
    display: block;
    width: 75%;
    height: 500px;
    margin: 20px auto 0px auto;
    padding: 20px auto 0px auto;

    @media screen and (max-width: 450px) {
        width: 100%;
        height: 45vh;
      }
    
    img {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    div{
        position: relative;
        
        p {
            position: absolute;
            top: -25px;
            right: 5px;
            text-align: right;
            font-size: small;
            color: #709bb5;
        }
    }
}

.diagram{
    width: 75%;
    height: auto;
    
    @media screen and (max-width: 450px) {
        width: 100%;
        height: auto;
      }
}

.post-figcaption{
    margin: 10px auto 20px auto;
    padding: 0px auto 20px auto;

    p{
        color: var(--gray-color);
        font-style: italic;
        text-align: center;
    }
}