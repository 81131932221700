.app__post-page {
    // padding: 50px 50px 0px 50px;

    .image {
        padding: 20px 0px;
        max-height: 500px;
        display: flex;
        overflow: hidden;

        img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
        }
    }

    a {
        color: #333;
        text-decoration: underline;
    }

    h1 {
        text-align: center;
        margin: 10px 0 5px;
    }

    time {
        text-align: center;
        display: block;
        font-size: 1rem;
        color: #aaa;
        margin: 10px 0;

    }

}