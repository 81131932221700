.app__experience {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__exp-works {
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  align-items:flex-start;
  margin-top: 1rem;

  .app__exp-item {
    width: 200px;
    height: 40%;
    flex-direction: column;
    

    margin: 0.5rem;
    padding: 0.1rem;
    border-radius: 0.5rem;
    background-color: #d1e2f6;
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    }

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
      padding: 2rem;
    }
  }
}

.app__exp-content {
  padding: 0.1rem;
  width: 95%;
  position: relative;
  flex-direction: column;


  h6 {
    margin-top: 0.5rem;
    line-height: 1.5;
    text-align: center;

    @media screen and (min-width: 2000px) {
      margin-top: 3rem;
    }
  }
}

.app__exp-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  // margin: 0.1rem;
  // padding: 0.1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;

  .app__exp-card-flow {
    position: relative;
    display: flex;
    justify-content: center;

    .down {
      width: 100%;
      height: 100px;
    }

    .icon {
      position: absolute;
      margin-top: 30px;
      align-items: center;
      height: 30px;
    }
  }

  .app__exp-card-snippet {
    height: 33%;
    width: 100%;
    position: relative;


    .app__exp-card-img {
      width: 50%;
      height: 30%;

      position: relative;

      img {
        width: 75%;
        border-radius: 0.5rem;
        object-fit: cover;
      }

      @media screen and (min-width: 2000px) {
        height: 350px;
      }
    }

    .app__exp-card-item {
      width: 50%;
      position: relative;
    }
  }
}

.app__exp-school {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  border-radius: 0.5rem;
  background-color: #fff;
  color: #000;

    .app__exp-school-item {

      display: flex;
      flex-wrap: wrap;
      padding: 1rem 1rem;
      // align-items:flex-start;

      .app__exp-school-item-elem{
        padding: 0px 1rem;
        height: auto;
        padding-bottom: 1rem;
        margin: 0.25rem 0.25rem;
        border-radius: 5px;
        background-color: #f0f0f0;
      }
  }


}