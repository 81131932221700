.post-h2 {
    font-size: 1.8em;
    margin: 20px 0px;
}

.post-h4 {
    margin: 20px 0px;
    font-size: 1.5em;
}

.post-h5 {
    margin: 20px 0px;
    font-size: 1.1em;
}

.post-p {
    line-height: 25px;
    margin: 10px 0px
}


.post-li {
    margin: 5px 20px;
    line-height: 25px;
}