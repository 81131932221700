.katex-inline {
    display: inline;
    font-size: large;
}

.katex-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: large;
    text-align: center;
    border-radius: 5px;
}