blockquote,
blockquote::before {
    color: #999;
}

blockquote {
    margin: 30px 0px 30px 0px;
    padding: 20px 50px 20px 50px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

blockquote::before {
    content: "\201C";
    display: block;
    font-size: 30px;
    height: 0;
    left: -20px;
    position: relative;
    top: -10px;
}